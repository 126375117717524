<template>
   <com-page-main class="empty">
      <h1 class="empty-title">404</h1>
      <div class="empty-des">您访问的页面不存在！</div>
   </com-page-main>
</template>

<script>
import ComPageMain from "@/components/PageMain";

export default {
   name: "PageNotFound",
   components: {
      ComPageMain,
   },
};
</script>

<style lang="scss" scoped>
.empty {
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: 100%;
   text-align: center;

   &-title {
      margin: 60px 0 0;
      font-size: 86px;
      font-weight: 900;
      line-height: 1;
   }

   &-des {
      margin-top: 20px;
      font-size: 24px;
   }
}
</style>
